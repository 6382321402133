<template>
  <div class="relative h-full min-w-screen">
    <img
      class="fixed top-0 left-0 w-full h-full"
      src="https://chihiro-inu.com/static/media/bg1.d033e007.jpg"
      alt=""
    />
    <div
      class="fixed top-0 left-0 w-full h-full bg-white display-block opacity-80"
      alt=""
    />
    <App-Header class="relative z-30" />
    <div class="relative pb-10 sm:py-10">
      <main>
        <div class="max-w-3xl mx-auto mt-10 sm:px-6 lg:px-8">
          <div class="flex items-center justify-center">
            <network :network="smartContractType" :target-network="targetNetwork"/>
          </div>
          <div
            v-if="isConnected && swap.length == 0 && !initLoading"
            class="max-w-full px-6 py-5 mx-auto mt-5 text-center text-white bg-red-600 rounded justify-between flex"
          >
            Please switch to ETH network, BSC network or MATIC network.
            <button class="btn" @click="switchNetwork">Switch Network</button>
          </div>
          <div class="my-5 ml-3 sm:ml-0">
            <nav
              v-if="swap.length > 0"
              class="flex flex-wrap space-x-4"
              aria-label="Tabs"
            >
              <a
                v-for="(sw, index) in swap"
                :key="index"
                href="#"
                class="px-3 py-2 text-sm font-medium uppercase rounded-md"
                :class="{
                  'text-white bg-linear-red': activeTab == index,
                  'text-black': activeTab != index,
                }"
                @click="changeActiveTab(index)"
              >
                {{ activeNetwork.short_name }} &lt;&gt; {{ sw.targetNetwork }}
              </a>
            </nav>
          </div>
          <Bridge :swap="swap[activeTab]" />
          <footer class="mt-10 text-base text-center text-black">
            <a
              href="https://oklg.io/"
              target="_blank"
              class="flex items-center justify-center"
            >
              Powered by OKLG
            </a>
          </footer>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AppHeader from "./components/AppHeader.vue";
import Spinner from "./components/Spinner.vue";
import Network from "./components/Network.vue";
import Bridge from "./Bridge.vue";

export default {
  components: {
    AppHeader,
    Spinner,
    Network,
    Bridge,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      "isConnected",
      "initLoading",
      "activeNetwork",
      "web3",
      "swap",
      "activeTab",
      "targetNetwork"
    ]),
    smartContractType() {
      if (this.isConnected && !this.initLoading) {
        if (this.activeNetwork.chain_id == 1) {
          return "eth";
        } else if (this.activeNetwork.chain_id == 137) {
          return "matic";
        } else if (this.activeNetwork.chain_id == 56) {
          return "bsc"
        }
      } else {
        return "eth";
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (
        typeof localStorage.getItem("chiroLoggedIn") !== "object" &&
        localStorage.getItem("chiroLoggedIn") == "true"
      ) {
        this.init(true);
      }
    });
  },
  methods: {
    ...mapActions([
      "init",
      "setActiveTab"
    ]),
    changeActiveTab(index) {
      this.setActiveTab(index)
    },
    async switchNetwork() {
      const chainId = '0x89'
      const chainName = 'Polygon'
      const nativeCurrency =
      {
          name: 'Polygon',
          symbol: 'MATIC',
          decimals: 18
      }
      const rpcUrls = ['https://polygon-rpc.com/']
      const blockExplorerUrls = ['https://polygonscan.com/']
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId,
            chainName,
            nativeCurrency,
            rpcUrls,
            blockExplorerUrls,
          },
        ],
      });
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `0x89`,
          },
        ],
      });
    },
  },
};
</script>

<style>
.bg-linear-red {
  background: linear-gradient(
    to right,
    rgba(39, 1, 155, 0.56) 0%,
    rgba(5, 4, 30, 0.99) 135%
  );
}
.bg-linear-blue-2 {
  background: radial-gradient(
      68.45% 125% at 3.97% 85.62%,
      rgb(50, 5, 137) 0px,
      rgba(255, 1, 221, 0) 100%
    ),
    radial-gradient(
      97.47% 137.91% at 21.03% 100%,
      rgb(95, 84, 185) 0px,
      rgba(92, 92, 255, 0) 100%
    ),
    radial-gradient(
      89.49% 70.67% at 17.26% 0px,
      rgb(25, 5, 106) 0px,
      rgba(92, 92, 255, 0) 100%
    ),
    radial-gradient(
      45.63% 105% at 95.63% 19.37%,
      rgb(81, 32, 135) 0px,
      rgba(30, 208, 244, 0) 100%
    ),
    rgb(0, 0, 0);
}
.bg-chiriho-block {
  background: rgba(122, 122, 122, 0.07);
  backdrop-filter: blur(15px);
}
body {
  background: rgb(18, 19, 28);
}
input {
  background: #1b0e21;
}
</style>
