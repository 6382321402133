<template>
  <svg
    class="w-9 h-9"
    viewBox="0 0 33 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_48:56)">
      <path
        d="M19.1943 20.6585L16.3273 23.3412L13.4606 20.6585L10.4911 17.8799L6.72205 21.4067L16.3273 30.3946L25.9329 21.4067L22.1638 17.8799L19.1943 20.6585Z"
        fill="#1e2f5e"
      />
      <path
        d="M13.4606 9.73606L16.3273 7.05334L19.1943 9.73606L22.1638 12.5147L25.9329 8.98788L16.3273 0L6.72205 8.98788L10.4911 12.5147L13.4606 9.73606Z"
        fill="#1e2f5e"
      />
      <path
        d="M4.54208 10.8839L0 15.1341L4.54208 19.3842L9.08415 15.1341L4.54208 10.8839Z"
        fill="#1e2f5e"
      />
      <path
        d="M28.1381 10.8839L23.596 15.134L28.1381 19.3841L32.6802 15.134L28.1381 10.8839Z"
        fill="#1e2f5e"
      />
      <path
        d="M16.3276 10.884L11.7855 15.1341L16.3276 19.3842L20.8697 15.1341L16.3276 10.884Z"
        fill="#1e2f5e"
      />
    </g>
    <defs>
      <clipPath id="clip0_48:56">
        <rect width="32.68" height="30.3945" fill="#1e2f5e" />
      </clipPath>
    </defs>
  </svg>
</template>
