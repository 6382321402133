import { createStore } from 'vuex'

import chiro from '../store/modules/chiro'

const store = createStore({
    modules: {
        chiro
    }
})

export default store