<template>
    <svg width="20" height="32" viewBox="0 0 20 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_16_127)">
        <path d="M9.99897 -2L0.5 15.2489C0.5 15.2489 9.55605 10.5653 9.99897 10.3876V-2Z" fill="#1e2f5e"/>
        <path d="M0.5 15.2489L9.99897 20.8808V10.3897C9.55605 10.5674 0.5 15.2489 0.5 15.2489Z" fill="#1e2f5e"/>
        <path d="M9.99902 10.3897V20.8828L19.5 15.2489C19.5 15.2489 10.444 10.5674 9.99902 10.3897Z" fill="#1e2f5e"/>
        <path d="M19.5 15.2489L9.99902 -2.00208V10.3938C10.444 10.5674 19.5 15.2489 19.5 15.2489Z" fill="#1e2f5e"/>
        <path d="M10.001 23.7609L0.5 18.1889L10.001 32V23.7609Z" fill="#1e2f5e"/>
        <path d="M10.0011 23.7609L19.5001 18.1889L10.0011 32V23.7609Z" fill="#1e2f5e"/>
        </g>
        <defs>
        <clipPath id="clip0_16_127">
        <rect width="19" height="32" fill="white" transform="translate(0.5)"/>
        </clipPath>
        </defs>
    </svg>

</template>

<script>
export default {
    name: "Ethereum"
}
</script>