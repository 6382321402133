<template>
  <div
    class="flex flex-col h-auto pb-10 mx-3 mt-5 rounded-lg sm:mx-auto bg-chiriho-block"
  >
    <div class="flex flex-col px-4 sm:px-12 mt-10">
      <div class="flex flex-col sm:items-center sm:justify-between sm:flex-row">
        <p class="font-medium break-all text-chiriho-maincolor">
          Enter the amount you want to swap
        </p>
        <div class="flex items-center" v-if="!isConnected">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="14" height="14" rx="7" fill="#EA4243" />
          </svg>
          <p class="ml-1 text-chiriho-maincolor">Not Connected</p>
        </div>
        <div class="flex items-center" v-else>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="14" height="14" rx="7" fill="#42EA85" />
          </svg>
          <p class="ml-1 text-chiriho-green">
            Connected to 
            <span v-if="smartContractType == 'eth'">ETH</span>
            <span v-else-if="smartContractType == 'matic'">MATIC</span>
            <span v-else-if="smartContractType == 'bsc'">BSC</span>
          </p>
        </div>
      </div>
      <div class="relative mt-5">
        <input
          v-model="swapAmount"
          :disabled="!swap"
          type="text"
          name="price"
          id="price"
          class="block w-full pt-5 pb-5 pr-10 text-xs text-center placeholder-black bg-white rounded outline-none sm:pr-16 text-opacity-40 bg-chihiro-input sm:text-xl placeholder-opacity-40"
          placeholder="Enter the amount you want to swap $CHIRO"
        />
        <button
          v-if="isConnected && swap"
          :disabled="!isConnected && initLoading"
          @click="
            swapAmount = userBalanceFormatted(swap.token).replace(/,/g, '')
          "
          class="absolute inset-y-0 right-0 flex items-center text-white rounded bg-linear-red"
        >
          <span class="mx-2 sm:mx-6">max</span>
        </button>
      </div>
      <div
        class="flex flex-col justify-between my-5 sm:flex-row sm:items-center"
      >
        <p class="font-medium text-black break-all">
          You are about to send: {{ swapAmountFormatted }} $CHIRO
        </p>
        <div
          class="flex flex-row mb-5 font-medium text-black break-all sm:mb-0 sm:flex-col"
        >
          $CHIRO Balance:
          <span v-if="swap" class="ml-1 text-right">{{
            userBalanceFormatted(swap.token)
          }}</span
          ><span v-else class="ml-1 text-right">0.00</span>
        </div>
      </div>
      <div class="flex items-center justify-center">
        <button
          v-if="!isConnected"
          type="button"
          @click="init"
          class="inline-flex items-center justify-center w-full p-3 text-lg text-white border-transparent rounded shadow-sm bg-linear-red"
        >
          Connect Wallet
        </button>
        <button
          v-if="isConnected && initLoading"
          type="button"
          class="inline-flex items-center justify-center w-full p-3 text-lg text-white border-transparent rounded shadow-sm bg-linear-red"
        >
          Connecting Wallet
          <Spinner />
        </button>
        <button
          v-else-if="isConnected"
          type="button"
          @click="sendSwap"
          :disabled="isLoading"
          class="inline-flex items-center justify-center w-full p-3 text-lg text-white border-transparent rounded shadow-sm bg-linear-red hover:bg-opacity-90"
        >
          Initiate Swap
          <Spinner v-if="isLoading" />
        </button>
      </div>
    </div>
  </div>
  <div
    class="flex flex-wrap items-center h-auto px-8 py-4 mx-3 mt-10 rounded-lg sm:mx-0 bg-linear-blue-2"
  >
    <div class="flex flex-col pl-5 mr-auto text-white">
      <p class="text-lg font-bold" v-if="swap && hasUnclaimedSentFromTarget">
        {{
          formatUnclaimedFromTargetAmount(swap.unclaimedSentFromTarget.amount)
        }}
        <span class="text-sm font-normal">CHIRO</span>
      </p>
      <p class="text-lg font-bold" v-else>
        0.00 <span class="text-sm font-normal">CHIRO</span>
      </p>
      <p class="text-sm">Available Tokens</p>
    </div>
    <div class="flex items-center w-auto sm:w-2/3" v-if="swap">
      <button
        @click="manualClaimModal = true"
        :disabled="isLoading"
        class="inline-flex items-center justify-center px-5 py-3 mr-4 text-base text-white border-transparent rounded shadow-sm sm:ml-auto bg-linear-red hover:bg-opacity-60"
      >
        Claim Tokens
      </button>
      <button
        v-if="hasUnclaimedInSourceAndNotInitiatedClaiming"
        @click="onRefundModal = true"
        :disabled="isLoading"
        class="inline-flex items-center justify-center px-5 py-3 text-base font-semibold text-white border-transparent rounded shadow-sm bg-chiriho-black bg-opacity-40 hover:bg-opacity-80"
      >
        Refund
      </button>
    </div>
  </div>
  <claim-token-modal
    v-if="manualClaimModal"
    :swap="swap"
    @close-modal="manualClaimModal = false"
  />
  <sent-token-modal
    v-if="successfullySentModal"
    @close-modal="successfullySentModal = false"
  />
  <refund-modal
    v-if="onRefundModal"
    @close-modal="onRefundModal = false"
    @refund="onRefundTokens"
  />
  <notifications position="top right" />
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import BigNumber from "bignumber.js";
import ClaimTokenModal from "./components/ClaimTokenModal.vue";
import SentTokenModal from "./components/SentTokenModal.vue";
import RefundModal from "./components/RefundModal.vue";
import Spinner from "./components/Spinner.vue";
import Network from "./components/Network.vue";

export default {
  components: {
    ClaimTokenModal,
    SentTokenModal,
    RefundModal,
    Spinner,
    Network,
  },
  props: ["swap"],
  data() {
    return {
      isLoading: false,
      swapAmount: null,
      manualClaimModal: false,
      successfullySentModal: false,
      onRefundModal: false,
      swap_id: "",
      unique_identifier: "",
      swap_amount: 0,
    };
  },
  computed: {
    ...mapGetters(["isConnected", "initLoading", "activeNetwork", "web3"]),
    smartContractType() {
      if (this.isConnected && !this.initLoading) {
        if (this.activeNetwork.chain_id == 1) {
          return "eth";
        } else if (this.activeNetwork.chain_id == 137) {
          return "matic";
        } else if (this.activeNetwork.chain_id == 56) {
          return "bsc"
        }
      } else {
        return "eth";
      }
    },
    swapAmountFormatted() {
      if (!this.swapAmount) {
        return 0;
      } else {
        return this.swapAmount;
      }
    },
    hasUnclaimedSentFromSource() {
      if (this.swap) {
        const targetSwap = this.swap.unclaimedSentFromSource;
        return (
          targetSwap &&
          new BigNumber(targetSwap.amount).gt(0) &&
          !targetSwap.isComplete &&
          !targetSwap.isRefunded
        );
      } else {
        return false;
      }
    },
    hasUnclaimedSentFromTarget() {
      if (this.swap) {
        const targetSwap = this.swap.unclaimedSentFromTarget;
        return (
          targetSwap &&
          new BigNumber(targetSwap.amount).gt(0) &&
          !targetSwap.isComplete &&
          !targetSwap.isRefunded
        );
      } else {
        return false;
      }
    },
    hasUnclaimedInSourceAndNotInitiatedClaiming() {
      if (this.swap) {
        const sourceSwap = this.swap.unclaimedSentFromSource;
        const targetSwap = this.swap.unclaimedSentFromTarget;
        return (
          sourceSwap &&
          new BigNumber(sourceSwap.amount).gt(0) &&
          !sourceSwap.isComplete &&
          !sourceSwap.isRefunded &&
          (!targetSwap || sourceSwap.id !== (targetSwap || {}).id)
        );
      } else {
        return false;
      }
    },
  },
  watch: {
    swap: {
      handler() {
        this.swapAmount = null
        this.swap_amount = 0
        this.swap_id = ""
        this.unique_identifier = ""
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions([
      "init",
      "sendTokensToSwap",
      "asaasInstanceGasCost",
      "asaasGetLatestUserSwap",
      "refundTokens",
      "getAllSwapContracts",
    ]),
    userBalanceFormatted(tokenInfo) {
      return new BigNumber(tokenInfo.userBalance)
        .div(new BigNumber(10).pow(tokenInfo.decimals))
        .toFixed();
    },
    async sendSwap() {
      try {
        this.isLoading = true;
        if (!this.swapAmount || this.swapAmount == "0.00") return;
        const amountToFormat = new BigNumber(this.swapAmount.replace(/,/g, ""));
        const correctSendTokenAmount = new BigNumber(amountToFormat)
          .times(new BigNumber(10).pow(this.swap.token.decimals))
          .toFixed(0);
        await this.asaasInstanceGasCost(this.swap.sourceContract);
        await this.sendTokensToSwap({
          amount: correctSendTokenAmount,
          sourceContract: this.swap.sourceContract,
          tokenContract: this.swap.token.address,
        });
        this.latestSwap = await this.asaasGetLatestUserSwap(
          this.swap.sourceContract
        );
        localStorage.chiroLatestSwapId = this.latestSwap.id;
        localStorage.chiroLatestSwapTimestamp = this.latestSwap.origTimestamp;
        localStorage.chiroLatestSwapNumTokens = new BigNumber(
          correctSendTokenAmount
        )
          .div(new BigNumber(10).pow(this.swap.token.decimals))
          .toFixed(0);
        this.successfullySentModal = true;
        this.$notify({
          type: "error",
          text: "Successfully initiated your swap!",
        });
      } catch (err) {
        this.$notify({ type: "error", text: err.message });
      } finally {
        this.isLoading = false;
      }
    },
    async onRefundTokens(refund) {
      try {
        if (refund) {
          this.isLoading = true;
          await this.refundTokens({
            instContract: this.swap.sourceContract,
            id: this.swap.unclaimedSentFromSource.id,
            timestamp: this.swap.unclaimedSentFromSource.origTimestamp,
            amount: this.swap.unclaimedSentFromSource.amount,
          });
          this.$notify({
            type: "error",
            text: "Your tokens were successfully refunded!",
          });
          await this.getAllSwapContracts();
        } else {
          this.onRefundModal = false;
        }
      } catch (err) {
        this.$notify({ type: "error", text: err.message });
      } finally {
        this.isLoading = false;
      }
    },
    formatUnclaimedFromTargetAmount(amount) {
      return new BigNumber(amount)
        .div(new BigNumber(10).pow(this.swap.targetToken.targetTokenDecimals))
        .toFormat();
    },
  },
};
</script>

<style>
.bg-linear-red {
  background: linear-gradient(
    to right,
    rgba(39, 1, 155, 0.56) 0%,
    rgba(5, 4, 30, 0.99) 135%
  );
}
.bg-linear-blue-2 {
  background: radial-gradient(
      68.45% 125% at 3.97% 85.62%,
      rgb(50, 5, 137) 0px,
      rgba(255, 1, 221, 0) 100%
    ),
    radial-gradient(
      97.47% 137.91% at 21.03% 100%,
      rgb(95, 84, 185) 0px,
      rgba(92, 92, 255, 0) 100%
    ),
    radial-gradient(
      89.49% 70.67% at 17.26% 0px,
      rgb(25, 5, 106) 0px,
      rgba(92, 92, 255, 0) 100%
    ),
    radial-gradient(
      45.63% 105% at 95.63% 19.37%,
      rgb(81, 32, 135) 0px,
      rgba(30, 208, 244, 0) 100%
    ),
    rgb(0, 0, 0);
}
.bg-chiriho-block {
  background: rgba(122, 122, 122, 0.07);
  backdrop-filter: blur(15px);
}
body {
  background: rgb(18, 19, 28);
}
input {
  background: #1b0e21;
}
</style>
